
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { MAIN_CONFIG } from "../../../config/main";
import CTAButton from "../CTAButton/CTAButton";
import FieldCheckbox from "../SVG/FieldCheckbox";
import FilterToggle from "../SVG/FilterToggle";
import "./SidebarModal.scss"


export default function SidebarModal({gpa = null, setGpa =() => {}, amount = null, setAmount= ()=> {}, programType =null, setProgramType =() => {},gradeLevel = [],setGradeLevel =() => {}, filters, addFilters, removeFilters , setDataLoad = ()=> {} , showFilters = true }) {
  const [panelWidth, setPanelWidth] = useState(0);

  const openNav = () => {
    setPanelWidth(300);
  };

  const closeNav = () => {
    setPanelWidth(0);
  };

  const checkAlreadyPresent = (_opt, value) => {
    if (gradeLevel.includes(value.value)) {
      let remaingLabel = gradeLevel.filter(op => op !== value.value)
      return remaingLabel
    }
    else {
      return [...gradeLevel, value.value]
    }
  }

  const selectOption = (value) => {
    if (value.label === 'Program Type') {
      setProgramType(value.value)
    } else if (value.label === 'Grade Level') {
      setGradeLevel(checkAlreadyPresent(gradeLevel , value))
    } else if (value.label === "Amount") {
      setAmount(value.value)
    }
  }
  const getCheckedStatus = (filter, value) => {
    switch (filter.label) {
      case 'Program Type': {
        return programType === value.value
      }
      case 'Amount': {
        return amount === value.value
      }
      case 'Grade Level': {
        return gradeLevel.includes(value.value)
      }
      default: {
        return false
      }
    }
  }
  const handleKeyDown = (e, filter, value) => {
    if (e.key === "Enter") {
      switch (filter.label) {
        case 'Program Type': {
          setProgramType(value.value)
          break;
        }
        case 'Amount': {
          setAmount(value.value)
          break;
        }
        case 'Grade Level': {
          if (gradeLevel.includes(value.value)) {
            let remaingLabel = gradeLevel.filter(op => op !== value.value)
            setGradeLevel(remaingLabel)
          }
          else {
            setGradeLevel([...gradeLevel, value.value])
          }
          break;
        }
        default: {
          null
          break;
        }
      }
    }
  }

  const renderOptions = (filter) => {
    switch (filter.optionType) {
      case 'range':
        const min = 0;
        const max = 4;
        return (
          <div className="filter-selector-item__dropdown-option-wrapper">
            <label className="H6Desktop">
              GPA
            <input
              type="range"
              min={min}
              max={max}
              step={0.1}
              value={gpa ? gpa : 0}
              onChange={event => {
                setGpa(event.target.value)
              }}
              className="filter-selector-item__dropdown-range-selector"
            />
            </label>
            <span className="filter-selector-item__dropdown-range-amount BodyDefaultBoldBlack">
              {gpa ? gpa : 0}
            </span>
          </div>
        );
      default:
        return <>
          <h1 className="H6Desktop"> {filter.label} </h1>
          {
            filter.options.map((option) => {
              return <>
                <label className="option-checkbox" tabIndex={-1}>

                  <span className="checkbox__input" tabIndex={0} onKeyDown={(e) => handleKeyDown(e, filter, option)}>
                    <input
                      type={filter.label === "Grade Level" ? 'checkbox' : 'radio'}
                      name={filter.label === "Grade Level" ? "grade-level" : filter.label === 'Program Type' ? "PROGRAM_TYPE" : "AWARD_AMOUNT"}
                      className="checkbox-circle"
                      onChange={() => {
                        selectOption({ label: filter.label, value: option.value })
                      }}
                      checked={getCheckedStatus(filter,option)}
                      tabIndex={-1}
                    />
                    <span className="checkbox__control">
                      <FieldCheckbox id={option.value}/>
                    </span>
                  </span>

                  <span className="checkbox-label BodyDefaultBoldBlack">
                    {option.label}
                  </span>

                </label>
              </>
            })
          }
        </>
    }
  }

  const renderFilters = () => {
    return filters.map((filter) => {
      return renderOptions(filter)
    })
  }

  const saveAllBtn = () => {
  
    setDataLoad(true)
    let data = {}

    // form_type__c: programType,
    // grade_level__c: gradeLevel,
    // amount__c: amount,
    // minimum_gpa__c: gpa

    if(!isEmpty(programType)){
      data["form_type__c"] = programType
    }
    if(!isEmpty(gradeLevel)){
      data["grade_level__c"] = gradeLevel
    }
    if(amount !== null){
      data["scholarship_fund_amount__c"] = amount
    }
    if(!isEmpty(gpa)){
      data["minimum_gpa__c"] = gpa
    }


    addFilters(data)
  }

  const applyAndClose = () => {
    saveAllBtn()
    closeNav()
  }

  const clearAllBtn = () => {
    window.location.reload()
  }


  return (
  
  
  <>

      <div
        id="mySidepanel"
        className="filterpanel background-color__light-grey"
        style={{ width: panelWidth,display:panelWidth!==0?'unset':'none' }}
      >
        <div className="cross-icon-and-filter" tabIndex={-1}>
        <span className="show-more-filter-icon"> {<FilterToggle />}</span>
        <span style={{fontSize: '20px', marginLeft:'10px', marginTop: "8px"}}>Filters</span>
        <a tabIndex={0} aria-label="Colse Filters" href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        </div>

        <div className="sidebar-filter-top-section" >
          {renderFilters()}
        </div>

        <div className="sidebar-filter-bottom-section">

          <button className="CTA color__white outline" onClick={clearAllBtn}>Clear filters</button>
          {/* <button className="filter-selector__clear-btn CTA color__green" style={{ backgroundColor: 'blue' }} onClick={saveAllBtn}>Apply filters</button> */}
          <CTAButton
            className="scholarship-body__card-cta CTA color__white small"
            style={{ backgroundColor: 'blue' }}
            onClick={applyAndClose}
          >
            Apply filters
          </CTAButton>
        </div>

      </div>

      {
      showFilters 
      && 
      <button 
      className="openbtn opentbn-sidebar-modal show-hide-toggle-filter"  style={{fontWeight: '600', padding: '10px'}}
      onClick={panelWidth < 1 ? openNav : closeNav}
     >
        {panelWidth > 0 ? "Hide filters" : "Show more filters"} <span className="show-more-filter-icon"> {  <FilterToggle />}</span>
      </button>
      }
    </>
  );
}


