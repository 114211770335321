import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ color = MAIN_CONFIG.COLORS.fillGreen }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <title>Filter Toggle</title>
        <desc>Filter Toggle</desc>
        <g fill="none" fillRule="evenodd">
            <g stroke={color} strokeWidth="1.5">
                <g>
                    <g>
                        <g>
                            <path
                                d="M18 14c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 4c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM3 16.5h12m-6-10h12.5"
                                transform="translate(-307 -583) translate(20 511) translate(0 56) translate(287 16)" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
