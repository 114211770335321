import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import "./filter-selector-item.scss";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FilterModal from '../FilterModal/FilterModal';
import { MAIN_CONFIG } from '../../../config/main';
import FieldCheckbox from '../../Components/SVG/FieldCheckbox';
import ArrowDown from '../../Components/SVG/ArrowDown';
import Field from '../Field/Field';
import { formatDateByMoment } from '../../Utility/FormatDate';


/**
 *
 * @param {String} type
 * @param {String} label
 * @param {Array} options
 * @param {'range', 'modal', 'date-range'} optionType
 * @param {Boolean} isMultiple enables multiple choice list for default option type
 * @param {Function} showFilter toggles filter controls
 * @param {Boolean} isVisible controls fitler visibility
 * @param {Function} saveClick
 * @param {Boolean} closeDropdown
 * @param {Function} getCount returns count of selected value supplied as an argument
 * @param {object[]} nestedFilters
 * 
 * @returns {JSX.Element}
 */
const FilterSelectorItem = ({
    type,
    label,
    options = [],
    optionType,
    isMultiple,
    showFilter,
    isVisible,
    saveClick,
    clearClick,
    getCount,
    nestedFilters,
    filterDataChange = false,
    selectedFilters = null,
    pageType = ''
}) => {
    const [activeOptions, setActiveOptions] = useState(selectedFilters);
    const [selectedOptions, setSelectedOptions] = useState(selectedFilters);
    const [selectedLabel, setSelectedLabel] = useState(label);
    const [date, setDate] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [expandCalender, setExpandCalender] = useState(false);
    const applyFilterPages = ["REPORTS", "DISBURSEMENTS", "DONATIONS"]
    const dropdownRef = useRef();
    const toggleDropdown = () => {
        showFilter(isVisible ? null : type);

        if (activeOptions) {
            setSelectedOptions(activeOptions);
        } else {
            setSelectedOptions(null);
        }

        // toggleBodyScrollLock();
    }

      function updateSelectedLabel(input) {
        if (!input || typeof input !== 'string') {
          return ""; // Handle null and undefined cases by returning an empty string or another suitable default value.
        }
      
        const maxLength = 30;
        const firstSpaceIndex = input.indexOf(' ', maxLength);
      
        if (firstSpaceIndex === -1) {
          return ""; // Handle the case where there's no white space after 30 characters.
        }
      
        const andMoreRegex = / and \d+ more/; // Match "and" followed by one or more digits.
      
        const andMoreMatch = input.match(andMoreRegex);
      
        if (andMoreMatch) {
          const replacedString = input.replace(andMoreRegex, '');
          return replacedString.substring(0, firstSpaceIndex) + andMoreMatch[0];
        } else {
          return input.substring(0, firstSpaceIndex); // Return the content up to the first white space.
        }
      }
      const modifiedLabel = updateSelectedLabel(selectedLabel);
      
    const toggleBodyScrollLock = target => {
        if (isVisible) {
            enableBodyScroll(target ? target : dropdownRef);
        } else {
            disableBodyScroll(target ? target : dropdownRef);
        }
    }

    const clearBtnClick = () => {

        setActiveOptions(null);
        setSelectedLabel(label);
        clearClick([type]);
        toggleDropdown();
        if (type !== 'tiles') {
            saveClick([{
                type,
                value: null,
            }]);
        }
        if(date){
            setDate("")
        }
        if (fromDate) {
            setFromDate(null);
        }
        if (toDate) {
            setToDate(null);
        }
    }

    const handleArrayOptions = () => {
        const value = selectedOptions.length > 0 ? selectedOptions : null;
        setActiveOptions(value);
        saveClick([{ type, value }]);
    };

    const handleDateOption = () => {
        const formattedDate = formatDateByMoment(date, 'YYYY-MM-DD');
        setActiveOptions(formattedDate);
        saveClick([{ type, value: formattedDate }]);
    };

    const handleDateRangeOption = () => {
        const from = fromDate ? formatDateByMoment(fromDate, 'YYYY-MM-DD') : null;
        const to = toDate ? formatDateByMoment(toDate, 'YYYY-MM-DD') : null;
        const value = { from, to };
        setActiveOptions(value);
        saveClick([{ type, value }]);
    };

    const handleDefaultOption = () => {
        const value = selectedOptions || null;
        setActiveOptions(value);
        saveClick([{ type, value }]);
    };

    const saveBtnClick = () => {
        if (Array.isArray(selectedOptions)) {
            handleArrayOptions();
        } else if (date) {
            handleDateOption();
        } else if (optionType === 'date-range') {
            handleDateRangeOption();
        } else {
            handleDefaultOption();
        }

        toggleDropdown();
    };

    useEffect(()=> {
        if(applyFilterPages.includes(pageType)) {
            if (Array.isArray(selectedOptions)) {
                handleArrayOptions();
            } else if (date) {
                handleDateOption();
            } else if (optionType === 'date-range') {
                handleDateRangeOption();
            } else {
                handleDefaultOption();
            }
        }
    }, [])
    const handleDateChange = (date) => {
        setFromDate(date)
        if (toDate != null && new Date(date) > new Date(toDate)) {
            setToDate(date)
        }
    }

    useEffect(() => {
        const setRangeLabel = () => {
            setSelectedLabel(`${label} ${activeOptions}`);
        };
    
        const setDateLabel = () => {
            if (activeOptions) {
                setSelectedLabel(`${label}: ${activeOptions}`);
            }
        };
    
        const setDateRangeLabel = () => {
            if (activeOptions) {
                const from = activeOptions.from ? activeOptions.from : '';
                const to = activeOptions.to ? activeOptions.to : '';
                setSelectedLabel(`${label}: ${from}${from && to ? ' To ' : ''}${to}`);
            }
        };
    
        const setArrayOptionsLabel = () => {
            if (activeOptions && Array.isArray(activeOptions) && activeOptions.length > 0) {
                let label = options?.find(option => {
                    return option?.value === activeOptions[0]
                })?.label

                if (Array.isArray(activeOptions) && activeOptions.length > 1) {
                    label += ` and ${activeOptions.length - 1} more`
                }

                setSelectedLabel(label?.repeat(1))
            } else if (activeOptions && !Array.isArray(activeOptions)) {
                let label = options?.find(option => {
                    return option?.value === activeOptions
                })?.label

                setSelectedLabel(label?.repeat(1))
            } else {
                setSelectedLabel(null)
            }
        };
    
        switch (optionType) {
            case 'range':
                setRangeLabel();
                break;
            case 'date':
                setDateLabel();
                break;
            case 'date-range':
                setDateRangeLabel();
                break;
            default:
                setArrayOptionsLabel();
                break;
        }
    }, [activeOptions, label, optionType, options]);       
    

    const selectOption = value => {
        if (isMultiple) {
            let currentSelectedOption = selectedOptions ? selectedOptions : []

            if (currentSelectedOption.indexOf(value) === -1) {
                currentSelectedOption.push(value)
            } else {
                currentSelectedOption = currentSelectedOption.filter(option => option !== value)
            }

            setSelectedOptions(Array.from(currentSelectedOption))
        } else {
            setSelectedOptions(value)
        }
    }

    const testIfOptionChecked = optionValue => {
        if ((selectedOptions !== 0 && !selectedOptions) || Array.isArray(selectedOptions) && selectedOptions.length === 0) {
            return false
        } else if (Array.isArray(selectedOptions) && selectedOptions.indexOf(optionValue) > -1) {
            return true
        } else {
            return selectedOptions === optionValue
        }
    }

    /**
     * Determines what to display in the body of the dropdown based on optionType
     * @returns {JSX.Element}
     */
    const renderOptions = () => {
        switch (optionType) {
            case 'range':
                const min = options[0];
                const max = options[1];
                return (
                    <div className="filter-selector-item__dropdown-option-wrapper">
                        <div className="filter-selector-item__dropdown-title CTA color__dark-grey">Select your G.P.A</div>
                        <input
                            type="range"
                            min={min}
                            max={max}
                            step={0.1}
                            value={selectedOptions ? selectedOptions : 0}
                            onChange={event => {
                                setSelectedOptions(event.target.value)
                            }}
                            className="filter-selector-item__dropdown-range-selector"
                        />
                        <span className="filter-selector-item__dropdown-range-amount BodyDefaultBoldBlack">
                            {selectedOptions ? selectedOptions : 0}
                        </span>
                    </div>
                );

            case 'date':
                return <Field
                    // label='Select By Date'
                    type='date'
                    className='field-group__field-half required Select-By-Date onboarding-screen__field'
                    value={date}
                    handleChange={(date) => { setDate(date)}}
                    setExpandCalender = {setExpandCalender}
                    placeholder = "Select By Date"
                    id="scholarship-launch-popup"
                />

            case 'date-range':
                return (
                    <div className="filter-selector-item__date-range-wrapper">
                        <Field
                            label='From'
                            type='date'
                            className='field-group__field-half required Select-By-Date onboarding-screen__field'
                            value={fromDate}
                            handleChange={(date) => { handleDateChange(date) }}
                            setExpandCalender={setExpandCalender}
                            placeholder="From Date"
                            id="from-date-field"
                        />
                        <Field
                            label='To'
                            type='date'
                            className='field-group__field-half required Select-By-Date onboarding-screen__field'
                            value={toDate}
                            handleChange={(date) => { setToDate(date) }}
                            setExpandCalender={setExpandCalender}
                            isFutureDatePicker={new Date(fromDate)}
                            placeholder="To Date"
                            id="to-date-field"
                        />
                    </div>
                );

            default:
                return options.map((option, i) => {
                    return (
                        <div
                            className="filter-selector-item__dropdown-option"
                            key={i}
                        >
                            <label className="option-checkbox" tabIndex={0}>

                                <span className="checkbox__input">
                                    <input
                                        type={isMultiple ? 'checkbox' : 'radio'}
                                        name="grade-level"
                                        className="checkbox-circle"
                                        onChange={() => {
                                            selectOption(option.value)
                                        }}
                                        checked={testIfOptionChecked(option.value)}
                                        tabIndex={-1}
                                    />
                                    <span className="checkbox__control">
                                        <FieldCheckbox />
                                    </span>
                                </span>

                                <span className="checkbox-label BodyDefaultBoldBlack">
                                    {option.label}
                                </span>
                                {getCount &&
                                    <span className="scholarships-amount BodySmallMediumBlack">
                                        {getCount(option.value)}
                                    </span>
                                }
                            </label>
                        </div>
                    )
                });
        }
    }

    if (optionType === 'modal') {
        return (
            <FilterModal
                heading={label}
                filters={nestedFilters}
                toggle={toggleDropdown}
                addFilters={saveClick}
                removeFilters={clearClick}
                isVisible={isVisible}
                filterDataChange={filterDataChange}
            />
        )
    }

    return (
            <div
                ref={dropdownRef}
                className="filter-selector-item"
                style={{
                    position: 'relative'
                }}
                tabIndex="0"
            >
                <button
                    className={`filter-selector-item__btn CTA color__white  ${activeOptions ? 'is-active' : ''} ${label} ${isVisible ? "" : ""}`}
                    onClick={toggleDropdown}
                    aria-label={`toggle menu ${isVisible ? "button active" : "button inactive"}`}
                >
                    {/* {activeOptions ? selectedLabel : label} */}
                    {activeOptions ? (pageType === 'APPLICANTS' && selectedLabel?.length > 30 && modifiedLabel ? modifiedLabel : selectedLabel) : label}
                    <span className="filter-selector-icon">
                        <ArrowDown className={`filter-selector__arrow-down-icon ${isVisible ? "" : ""}`} color={activeOptions ? MAIN_CONFIG.COLORS.fillBlueColor : MAIN_CONFIG.COLORS.fillGreen} ariaLabel="Arrow Down" ariaLabelDescription="Click here to toggle filter option" />
                    </span>
                </button>
                <div
                    className={`
                        filter-selector-item__dropdown
                        ${isVisible ? 'is-visible' : ''}
                        ${optionType}
                        ${label}
                    `}
                >
                    <div className={`filter-selector-item__dropdown-content ${expandCalender && "expand-modal"}`}>
                        {renderOptions()}
                    </div>
                    <div className="filter-selector-item__dropdown-bottom">
                        <button className="filter-selector-item__clear-btn CTA" onClick={clearBtnClick} aria-label="Clear button">Clear</button>
                        <button className="filter-selector-item__save-btn CTA" onClick={saveBtnClick} aria-label="Save button">Save</button>
                    </div>
                </div>
            </div>
    )
}

FilterSelectorItem.propTypes = {
    label: PropTypes.shape({
        repeat: PropTypes.any
    }),
  };

  export default FilterSelectorItem;