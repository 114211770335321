import React from 'react';
import PropTypes from 'prop-types';
import './slider-button.scss';

const SliderButton = ({ checked = false, name = "", handleToggle = () => {}, ariaLabel = "", ariaDescribedBy = "", ariaLabelledBy = "" }) => {
    return (
        <label className='slider-button'>
        <input
            type='checkbox'
            name={name}
            checked={checked}
            onChange={(e) => handleToggle(e.target.checked, e.target.name)}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedBy}
            aria-labelledby={ariaLabelledBy}
            role="switch"
            aria-checked={checked}
        />
        <span className='slider'></span>
        <span className='EyebrowGreen yes'>Yes</span>
        <span className='BodySmallRegularBlack no'>No</span>
    </label>
    );
};

SliderButton.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    handleToggle: PropTypes.func,
    ariaLabel: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    ariaLabelledBy: PropTypes.string
};

export default SliderButton;