import React, { useState, useEffect, useRef } from 'react';

import Divider from '../Divider/Divider';
import SliderButton from '../SliderButton/SliderButton';
import Field from '../Field/Field';
import { CreateOptions, loadSelectedMultiListValue, loadSelectedListValue } from '../../Utility/SelectListLoader';
import './FilterModal.scss';
import FieldCheckbox from '../../Components/SVG/FieldCheckbox';
import { MAIN_CONFIG } from '../../../config/main';



/**
 * Returns filters grouped as modal
 * 
 * @param {object} props
 * @param {string} props.heading
 * @param {object[]} props.filters
 * @param {function} props.toggle toggles visibility
 * @param {function} props.selectOption selects option
 * @param {function} props.addFilters applies filters to current list
 * @param {function} props.removeFilters removes filters from current list
 * @param {boolean} props.isVisible determines if filters are currently visible
 * 
 * @returns {ReactElement}
 */

export default function FilterModal(props) {
  const {
    heading,
    filters,
    toggle,
    addFilters,
    removeFilters,
    isVisible,
    filterDataChange = false
  } = props

  const modal = useRef()

  /**
   * Filters applied to list
   */

  const [
    activeFilters,
    setActiveFilters,
  ] = useState([])

  /**
   * Filters selected while component
   * is visible
   */

  const [
    selectedFilters,
    setSelectedFilters,
  ] = useState([])

  useEffect(() => {
    setSelectedFilters(activeFilters)

    if (isVisible) {
      document.body.classList.add('is-modal-visible')
    } else {
      document.body.classList.remove('is-modal-visible')
    }
  }, [isVisible])

  /**
   * Mark filter as selected
   * 
   * @param {string} filterType 
   * @param {string} value 
   */

  const setSelected = (filterType, value, isMultiple) => {
    let currentFilters = [...selectedFilters]
    let currentFilter = {
      [filterType]: value
    }

    const isSelected = selectedFilters.find(
      item => JSON.stringify(item) === JSON.stringify(currentFilter)
    )

    if (isSelected && isMultiple) {
      currentFilters = currentFilters.filter(
        item => JSON.stringify(item) !== JSON.stringify(currentFilter)
      )
    } else {
      if (!isMultiple) {
        currentFilters = currentFilters.filter(
          item => Object.keys(item)[0] !== filterType
        )
      }

      if (value) {
        currentFilters.push(currentFilter)
      }
    }

    setSelectedFilters(currentFilters)
  }

  /**
   * Mark filter as selected
   * 
   * @param {string} filterType 
   * @param {string} value 
   */

  // const setonChangeSelected = (filterType, value, isMultiple) => {
  //   let currentFilters = [...selectedFilters]
  //   let currentFilter = {
  //     [filterType]: value
  //   }

  //   if (Array.isArray(value)) {
  //     value.map(row => {
  //       currentFilter = {
  //         [filterType]: row.value
  //       }
  //     })
  //   }

  //   const isSelected = selectedFilters.find(
  //     item => {
  //       let type = Object.keys(item)[0]

  //       if (JSON.stringify(item) === JSON.stringify(currentFilter)) {
  //         return item
  //       } else
  //         if (type === filterType && value === '') {
  //           currentFilter = {
  //             [filterType]: item[filterType]
  //           }
  //           return item
  //         }

  //     }
  //   )
  //   if (isSelected && isMultiple) {
  //     currentFilters = currentFilters.filter(
  //       item => JSON.stringify(item) !== JSON.stringify(currentFilter)
  //     )
  //   } else {
  //     if (!isMultiple) {
  //       currentFilters = currentFilters.filter(
  //         item => Object.keys(item)[0] !== filterType
  //       )
  //     }

  //     if (value) {
  //       currentFilters.push(currentFilter)
  //     }
  //   }

  //   setSelectedFilters(currentFilters)
  // }
  
  const setonChangeSelected = (filterType, value, isMultiple) => {
    let currentFilters = [...selectedFilters];
    if (!isMultiple) {
      currentFilters = currentFilters.filter(item => Object.keys(item)[0] !== filterType);
    }

    if (Array.isArray(value)) {
      currentFilters = currentFilters.filter(item => {
        const type = Object.keys(item)[0];
        return type !== filterType;
      });
      value.forEach(row => {
        currentFilters.push({
          [filterType]: row.value
        });
      });
    } else if (value === '') {
      currentFilters = currentFilters.filter(item => {
        const type = Object.keys(item)[0];
        return type !== filterType;
      });
    } else {
      currentFilters.push({
        [filterType]: value
      });
    }
    setSelectedFilters(currentFilters);
  };

  /**
   * Hides section
   */

  const close = () => {
    toggle()
  }

  /**
   * Removes currently active filters and
   * hides section
   */

  const clear = () => {
    const categories = filters.map(filter => filter.type)

    removeFilters(categories)

    setActiveFilters([])
    setSelectedFilters([])

    toggle()
    const newFilters = consolidateFilters([], categories)
    addFilters(newFilters)

  }

  /**
   * Marks currently selected filters as active and
   * hides section
   */

  const save = () => {
    const categories = filters.map(filter => {
      return {
        type: filter.type,
        isMultiple: filter.isMultiple,
      }
    })

    let newFilters = selectedFilters
    if (!filterDataChange) {
      newFilters = consolidateFilters(selectedFilters, categories)
    }
    if (newFilters.length === 0) {
      clear()

      return
    }
    addFilters(newFilters)
    setActiveFilters(selectedFilters)

    toggle()
  }

  /**
   * Tests if filter should be selected after component
   * becomes visible to avoid a situation when uncommitted 
   * filters are visible after toggling component off and on.
   * 
   * @param {string} type 
   * @param {string} value 
   */

  const testIfOptionSelected = (type, value) => {
    const selectedFilter = selectedFilters.find(filter => {
      const filterType = Object.keys(filter)[0]

      return filterType === type && filter[filterType] === value
    })

    return selectedFilter ? true : false
  }

  /**
   * Tests if filter should be selected after component
   * becomes visible to avoid a situation when uncommitted 
   * filters are visible after toggling component off and on.
   * 
   * @param {string} type 
   * @param {string} value 
   */

  const testIfOptionMultiSelected = (OPTIONS, type) => {
    let value = []
    let filterName = ''
    const selectedFilter = selectedFilters.find(filter => {
      const filterType = Object.keys(filter)[0]
      if (filterType === type) {
        filterName = filterType
        value.push(filter[filterType])
      }
    })
    let multValue = []
    if (filterName === type) {
      multValue = loadSelectedMultiListValue(OPTIONS, value.join(';'))
    }
    return multValue
  }

  /**
   * Handles SliderButton filter selection. Selected state results
   * in filter type defined as 'true'.
   * 
   * @param {boolean} value
   * @param {string} filterType 
   */

  const handleSliderButton = (value, filterType) => {
    setSelected(filterType, value === true ? 'true' : null)
  }

  const onHandleChange = (filterType, value, type) => {
    let currentFilters = [...selectedFilters]

    if (currentFilters.length === 0) {
      let currentFilter = {
        [filterType]: { "value": value ? value.value && '' : value, "operator": value ? value.value : '' }
      }
      currentFilters.push(currentFilter)
    } else {
      let keyArg = []
      currentFilters.filter(r => {
        const filterKey = Object.keys(r)[0]
        keyArg.push(filterKey)
      })
      if (keyArg.includes(filterType)) {
        currentFilters.filter(r => {
          const filterKey = Object.keys(r)[0]
          if (filterType === filterKey) {
            if (type === 'input') {
              r[filterType].value = value
            }
            if (type === 'select') {
              r[filterType].operator = value.value
            }
          }
        })
      } else {
        let currentFilter = {
          [filterType]: { "value": value ? value.value && '' : value, "operator": value ? value.value : '' }
        }
        currentFilters.push(currentFilter)
      }
    }
    setSelectedFilters(currentFilters)

  }

  /**
   * Tests if filter should be selected after component
   * becomes visible to avoid a situation when uncommitted 
   * filters are visible after toggling component off and on.
   * 
   * @param {string} type 
   * @param {string} value 
   */

  const valueSelectForCustom = (field, type) => {
    let value = ''
    const selectedFilter = selectedFilters.find(filter => {
      const filterType = Object.keys(filter)[0]
      if (filterType === field) {
        if (type === 'input') {
          value = filter[filterType].value
        }
        if (type === 'select') {
          value = filter[filterType].operator ? loadSelectedListValue(MAIN_CONFIG.OPERATORS, filter[filterType].operator) : ''
        }
      }
    })
    return value
  }

  return (
    <div
      ref={modal}
      className="filter-selector-item"
    >
      {isVisible &&
        <div
          className="filter-selector-item__overlay"
          onClick={toggle}
        />
      }
      <button
        className={`
            filter-selector-item__btn
            CTA
            color__white
            ${activeFilters.length > 0 ? 'is-active' : ''}
            ${isVisible ? "mobile-common-dropdown-down-arrow" : "mobile-common-dropdown-right-arrow"}
          `}
        onClick={toggle}
        aria-label={`menu toggle ${isVisible ? "button active" : "button inactive"}`}
      >
        {heading}
      </button>
      <div
        className={`
              filter-selector-item__dropdown
              ${isVisible ? 'is-visible' : ''}
              modal
          `}
      >
        <div className="filter-selector-item__dropdown-content MoreFiltersModal">
          <div className="filter-selector-item__modal">
            <div className="filter-selector-item__modal-heading">
              <div className="filter-selector-item__modal-title H1DesktopGreen">
                {heading}
              </div>
              <button
                className="filter-selector-item__modal-close-btn H6DesktopGrey"
                onClick={close}
                aria-label="close filters"
              >
                X
              </button>
            </div>
            <div className="filter-selector-item__modal-content">
              {
                filters.map((category, index) => {
                  if (category.optionType === 'toggle') {
                    return (
                      <div
                        className="filter-selector-item__modal-section wide"
                        key={index}
                      >
                        <div className="filter-selector-item__modal-section-heading">
                          <span className="filter-selector-item__modal-section-title H6DesktopGreen">
                            {category.heading}
                          </span>
                          <span className="filter-selector-item__modal-section-subtitle BodySmallMedium color__dark-grey">
                            {category.description}
                          </span>
                        </div>
                        <SliderButton
                          name={category.type}
                          handleToggle={handleSliderButton}
                          checked={selectedFilters.find(filter => Object.keys(filter)[0] === category.type) ? true : false}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <Category
                        category={category}
                        onChange={setSelected}
                        onHandleChange={onHandleChange}
                        onChangeSelect={setonChangeSelected}
                        testIfOptionSelected={testIfOptionSelected}
                        testIfOptionMultiSelected={testIfOptionMultiSelected}
                        valueSelectForCustom={valueSelectForCustom}
                        key={index}
                      />
                    )
                  }
                })
              }
            </div>
            <div className="filter-selector-item__dropdown-bottom">
              <button className="filter-selector-item__clear-btn CTA" onClick={clear} aria-label="Clear button">Clear</button>
              <button className="filter-selector-item__save-btn CTA" onClick={save} aria-label="Save button">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


/**
 * Returns filter Category
 * 
 * @param {object} props
 * @param {object} props.category category definition
 * @param {function} props.testIfOptionSelected test if filter should be checked
 *                                              after toggling modal visibility on
 * 
 * @returns {ReactElement}
 */

function Category(props) {
  const {
    category,
    onChange,
    onChangeSelect,
    onHandleChange,
    testIfOptionSelected,
    testIfOptionMultiSelected,
    valueSelectForCustom,
  } = props
  return (
    <div className={`filter-selector-item__modal-section ${category.optionType === 'input' && "university-staff-more-filter"}`}>
      <h5 className="filter-selector-item__modal-section-title H4DesktopGreen">
        {category.heading}
      </h5>
      <Divider className="filter-selector-item__modal-section-divider" />

      {
        category.optionType === 'toggle' ?
          <div className="filter-selector-item__modal-checkbox-wrapper">
            <div className="filter-selector-item__modal-section wide">
              <div className="filter-selector-item__modal-section-heading">
                <span className="filter-selector-item__modal-section-title H6DesktopGreen">No Essay</span>
                <span
                  className="filter-selector-item__modal-section-subtitle BodySmallMedium color__dark-grey">
                  Only show scholarships that do not require essays
                </span>
              </div>
              <SliderButton />
            </div>
          </div>
          :
          category.optionType === 'checkbox' ?
            <div className="filter-selector-item__modal-checkbox-wrapper">
              {category.options.map((option, i) => {
                return (
                  <label
                    className="option-checkbox"
                    key={i}
                  >

                    <span className="checkbox__input">
                      <input
                        type={category.isMultiple ? 'checkbox' : 'radio'}
                        name={category.type}
                        className="filter-selector-item__modal-checkbox checkbox-circle"
                        onChange={() => {
                          onChange(category.type, option.value, category.isMultiple)
                        }}
                        checked={testIfOptionSelected(category.type, option.value)}
                      />

                      <span className="checkbox__control">
                        <FieldCheckbox />
                      </span>
                    </span>
                    <span className="checkbox-label BodyDefaultBoldBlack">
                      {option.label}
                    </span>
                  </label>
                )
              })}
            </div>
            :
            category.optionType === 'multiselect' ?
              <div className="filter-selector-item__modal-section more-filter-multiselect-fields">
                <Field
                  placeholder='Select...'
                  type='multiselect'
                  options={CreateOptions(category.options)}
                  handleChange={value => onChangeSelect(category.type, value, category.isMultiple)}
                  value={testIfOptionMultiSelected(CreateOptions(category.options), category.type)}
                />
              </div>
              :
              category.optionType === 'selectmulti' ?
                <div className="filter-selector-item__modal-section more-filter-multiselect-fields">
                  <Field
                    placeholder='Select...'
                    type='multiselect'
                    options={category.options}
                    handleChange={value => onChangeSelect(category.type, value, category.isMultiple)}
                    value={testIfOptionMultiSelected(category.options, category.type)}
                  />
                </div>
                :
                category.optionType === 'input' &&

                <div className="filter-selector-item__modal-section more-filter-multiselect-fields-with-operator">
                  <div className="more-filter-multiselect-fields-with-operator__dropdown-field">

                    <Field
                      placeholder='Select'
                      type='select'
                      options={MAIN_CONFIG.OPERATORS}
                      handleChange={value => onHandleChange(category.type, value, 'select')}
                      value={valueSelectForCustom(category.type, 'select')}
                    />
                  </div>
                  <div className="more-filter-multiselect-fields-with-operator__input-field">

                    <Field
                      placeholder='Enter keyword...'
                      type='text'
                      handleChange={value => onHandleChange(category.type, value, 'input')}
                      value={valueSelectForCustom(category.type, 'input')}
                    />
                  </div>
                </div>
      }
    </div>
  )
}

function consolidateFilters(filters, categories) {
  let consolidatedFilters = {}
  let result = []

  for (let i = 0, total = categories.length; i < total; i++) {
    const category = categories[i].type

    consolidatedFilters[category] = null
  }

  for (let i = 0, total = filters.length; i < total; i++) {
    const filter = filters[i]
    const key = Object.keys(filter)[0]
    const categorySettings = categories.find(item => item.type === key)

    if (consolidatedFilters[key]) {
      if (categorySettings.isMultiple) {
        consolidatedFilters[key].push(filter[key])
      } else {
        consolidatedFilters[key] = filter[key]
      }
    } else {
      if (categorySettings.isMultiple) {
        consolidatedFilters[key] = [filter[key]]
      } else {
        consolidatedFilters[key] = filter[key]
      }
    }
  }

  for (let i = 0, total = Object.keys(consolidatedFilters).length; i < total; i++) {
    const key = Object.keys(consolidatedFilters)[i]

    result.push({
      type: key,
      value: consolidatedFilters[key],
    })
  }

  return result
}
