import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import "./filter-selector.scss";
import FilterSelectorItem from "../FilterSelectorItem/FilterSelectorItem";
import { BREAKPOINTS } from "../../Utility/Breakpoints";
import FormatNumber from "../../Utility/FormatNumber";
import StateSelector from "../StateSelector/StateSelector";
import { handleResize } from "../../Utility/HelperFunctions";
import cookies from "next-cookies";
import { MAIN_CONFIG } from "../../../config/main";
const { USER_CONTEXT } = MAIN_CONFIG;
import Field from "../../Components/Field/Field";
import { useRouter } from "next/router";
import SidebarModal from "../SideBarModal/SidebarModal";

const DESKTOP_WIDTH = BREAKPOINTS.medium;

const FilterSelector = ({
  label,
  count,
  filters,
  addFilters,
  removeFilters,
  className = "",
  wideFilter = false,
  globalSearch = false,
  stateSelector = false,
  isApplicantList = false,
  data = 0,
  activeFilters = {},
  userContext,
  handleSearch = () => {},
  filterDataChange = false,
  notShowHead = true,
  selectedFilters = null,
  showFilters = true,
  filterButtonScholarships = [],
  handleDonationTabClick,
  scholarshipButtonActive,
  setScholarshipButtonActive,
  setSubmitted = () => {},
  accountData,
  donationTabActive = false,
  showProgramFilters = true,
  account,
  setSearchValue = () => {},
  searchValue = "",
  setDataLoad = () => {},
  setAccountLoaderVisible = () => {},
  programFilterActiveTab,
  setProgramFilterActiveTab = () => {},
  fetchScholarships = () => {},
  handleProgramTabClick = () => {},
  pageType = '',
  isAchInfoFilters = false
}) => {
  const router = useRouter();
  let route = router.query;

  const LOADING_SCREEN_SECONDS = 2;

  let selectedAccount = accountData?.availableAccounts
    ? accountData?.availableAccounts.filter(
        (account) =>
          account.url_name__c === route.indexId ||
          account.sfid === route.indexId ||
          (account.sfid === cookies("currentAccount").currentAccount &&
            !route?.indexId)
      )
    : [];

  const checkLogout = userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT;

  const [key, setKey] = useState(Date.now());
  const [isMobile, setIsMobile] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [acount, setAccount] = useState({
    label:
      selectedAccount.length > 0
        ? selectedAccount[0].name
        : "All Programs",
    value: selectedAccount.length > 0 ? selectedAccount[0].sfid : "",
  });
  const [visibleFilterDropdown, setVisibleFilterDropdown] = useState(null);

  const [programType, setProgramType] = useState(null);
  const [gradeLevel, setGradeLevel] = useState([]);
  const [amount, setAmount] = useState(null);
  const [gpa, setGpa] = useState(null);

  const modal = useRef(null);

  const { user, currentAccount, sectionVisibilityOptions } = accountData;

  const resize = () =>
    handleResize(setIsMobile, window.innerWidth < DESKTOP_WIDTH);

  useEffect(() => {
    setIsMobile(window.innerWidth < DESKTOP_WIDTH);
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const [searchShow, setSearchShow] = useState(false);

  const showFilterCondition = showProgramFilters
    ? showFilters
      ? true
      : account?.show_scholarship_filters__c ||
        accountData?.currentAccount?.show_scholarship_filters__c
    : false;

  const handleAccountSwitch = async (account) => {
    setDataLoad(true);
    var isRediractLocal = localStorage.getItem("isRedireactSave")
      ? JSON.parse(localStorage.getItem("isRedireactSave"))
      : false;
    // if (pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY && isRediractLocal || pageType == MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME && action === "submit" || route.applicationstepid) {
    // 	alretMsg()
    // } else {
    setAccount(account);
    setAccountLoaderVisible(true, account);
    document.cookie = `currentAccount=${account.sfid};path=/`;
    // Remove marketplace from storage
    localStorage.marketPlace = "";
    document.cookie =
      "marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";

    setTimeout(() => {
      window.location.href = `/applicant/${account.url_name || account.sfid}`;
      // router.push(`/applicant/`, undefined, { shallow: true })
    }, LOADING_SCREEN_SECONDS * 1000);

    // }
  };


  var accounts = accountData.availableAccounts?.map((account) => {
    return {
      firstName: user?.first_name,
      lastName: user?.last_name,
      label: account?.name,
      companyLogo: account?.account_logo_url__c,
      primaryColor: account?.primary_color__c,
      secondaryColor: account?.secondary_color__c,
      profileImage: user?.avatar,
      sfid: account?.sfid,
      value: account?.sfid,
      url_name: account?.url_name__c,
    };
  });
  accounts?.push({ label: "All Programs", value: "", sfid: "" });
  accounts?.reverse();

  const renderFilters = () => {
    return (
      <>
        { (
          <div className="filter-selector__leading">
            {filters.map((filter, i) => (
              <FilterSelectorItem
                key={i}
                type={filter.type}
                label={filter.label}
                options={filter.options}
                optionType={filter?.optionType}
                isMultiple={filter.isMultiple}
                showFilter={setVisibleFilterDropdown}
                isVisible={filter.type === visibleFilterDropdown}
                saveClick={addFilters}
                clearClick={removeFilters}
                getCount={filter.getCount}
                nestedFilters={filter.nestedFilters}
                filterDataChange={filterDataChange}
                selectedFilters={pageType === 'REPORTS' ? selectedFilters?.[filter.type] : selectedFilters}
                pageType={pageType}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  /**
   * Renders the global filter bar shown on mobile which opens up to the different filters that can be modified
   *
   * @param {boolean} isActive
   *
   * @returns {JSX.Element}
   */
  const renderMobileFilterBar = (isActive) => (
    <>
      {isMobile &&  (
        <div className="filter-selector__global-search-container">
          <button className="filter-selector__global-search-btn mobile-search-scholarship-container">
            <Field
              className="mobile-search-input"
              placeholder="Search Programs"
              type="text"
              name="search_value"
              handleChange={(val) => handleSearch(val)}
            />
          </button>
        </div>
      )}

      {/* <div className="filter-selector__dropdown background-color__white">
                <div className="filter-selector__state-option">
                    <StateSelector
                        isMobile={isMobile}
                        type="state_eligibility__c"
                        saveClick={addFilters}
                        clearClick={removeFilters}
                    />
                </div>
                <button
                    className={`
                    filter-selector__btn
                    ${isActive ? 'is-active' : ''}
                 `}
                    onClick={toggleMobileFilterModal}
                    aria-label={`toggle menu ${isActive ? "button active" : "button inactive"}`}
                 >
                    <FilterToggle />
                </button>
            </div> */}
    </>
  );


  const toggleMobileFilterModal = () => {
    if (mobileFilterOpen) {
      // enableBodyScroll(modal);
      // clearAllBodyScrollLocks();
    } else {
      // disableBodyScroll(modal);
    }

    setMobileFilterOpen(!mobileFilterOpen);
  };

  /**
   * Removes active filters and closes
   */
  const clearAll = () => {
    const filterTypes = [];

    filters.forEach((filter) => {
      if (filter.type === "nested") {
        filter.nestedFilters.forEach((nestedFilter) => {
          filterTypes.push(nestedFilter.type);
        });
      } else {
        filterTypes.push(filter.type);
      }
    });

    removeFilters(filterTypes);
    toggleMobileFilterModal();

    setKey(Date.now());
  };

  const renderGlobalSearch = () => {
    return (
        <div className="filter-selector__global-search-container">
          <div className="filter-selector__global-search-btn applicant-search">
            {
              <Field
                placeholder="Search Programs"
                type="text"
                name="search_value"
                handleChange={(val) => handleSearch(val)}
                value={searchValue}
              />
            }
          </div>
        </div>
    );
  };

  const renderStateSelector = () => {
    if (stateSelector && !isMobile) {
      return (
        <div>
          {USER_CONTEXT.LOGGED_OUT == userContext && (
            <StateSelector
              onClick={(value) => {}}
              type="state_eligibility__c"
              saveClick={addFilters}
              clearClick={removeFilters}
            />
          )}
        </div>
      );
    }
  };

  const renderHeading = () => {
    let headerCondition =
      cookies("context").context === "APPLICANT" &&
      cookies("currentAccount").currentAccount
        ? true
        : false;

    // if (isApplicantList) {
    //     return `${(count === 1) ? label.singular : label.plural} (${FormatNumber(count)})`
    // } else {
    //     return `${headerCondition ? "" : FormatNumber(count)} ${!headerCondition ? ((count === 1) ? label.singular : label.plural) : ""}`
    // }

    if (isApplicantList) {
      return `${count === 1 ? label.singular : label.plural} (${FormatNumber(
        count
      )})`;
    } else {
      return (
        <>
          <h1 className="public-marketplace-heading H1DesktopBlack">
            Explore The{" "}
            <span className="marketplace H1DesktopGreen">Marketplace</span>
          </h1>
          <p className="public-marketplace-heading-text BodyDefaultRegularBlack">
          Below is our marketplace for grants, scholarships, and other forms of financial assistance! Our goal is to make it easy for you to find and apply for the programs that help you reach your goals!
          </p>
        </>
      );
    }
  };

  const renderAccountSelector = () => {
    return (
      <>
        {accountData.availableAccounts.length > 0 &&
          !accountData.enable_sso__c && (
            <div className="program-sponsors applicant-accounts-dropdown applicant-dropdown">
              <Field
                className="field-group__field-full"
                type="select"
                placeholder="Select Account"
                options={accounts}
                value={acount}
                label=""
                handleChange={(val) => handleAccountSwitch(val)}
              />
            </div>
          )}
      </>
    );
  };

  const checkActiveClass = () => {
    if (gpa || programType || gradeLevel?.length > 0 || amount) {
      return "active";
    }
    return "";
  };

  const handleDonationClick = (e, filter) => {
    handleDonationTabClick(e, filter === "Donate" ? true : false);
    setScholarshipButtonActive(filter);
    setSubmitted(true);
    setSearchValue("");
  };

  const handleKeyDown = (event,callBack) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callBack(); // Call the onClick function when Enter is pressed
    }
  };

  const programFilterTabs = () => {
    return (
      <>
        <div className="filter-and-account-search-program-container">
          <div className="programtabs BodyDefaultRegularBlack">
            {showFilters && (
              <>
                <span className="BodyDefaultRegularBlack" style={{ fontWeight: '600'}}>Display</span>
                <div
                  className={
                    programFilterActiveTab == "All"
                      ? "programtab active"
                      : "programtab"
                  }
                  onClick={() => handleProgramTabClick("All")}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e)=>handleKeyDown(e,()=>handleProgramTabClick("All"))}
                >
                  All
                </div>
                <div
                  className={
                    programFilterActiveTab == "Accepting Applications"
                      ? "programtab active"
                      : "programtab"
                  }
                  onClick={() =>
                    handleProgramTabClick(
                      "Accepting Applications",
                      "accepting_applications"
                    )
                  }
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e)=>handleKeyDown(e,()=>handleProgramTabClick(
                    "Accepting Applications",
                    "accepting_applications"
                  ))}
                >
                  Accepting Applications
                </div>
                <div
                  className={
                    programFilterActiveTab == "Opening Soon"
                      ? "programtab active"
                      : "programtab"
                  }
                  onClick={() =>
                    handleProgramTabClick("Opening Soon", "opening_soon")
                  }
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e)=>handleKeyDown(e,()=>handleProgramTabClick("Opening Soon", "opening_soon"))}
                >
                  Opening Soon
                </div>
                <div
                  className={
                    programFilterActiveTab == "Coming Soon"
                      ? "programtab active"
                      : "programtab"
                  }
                  onClick={() =>
                    handleProgramTabClick("Coming Soon", "coming_soon")
                  }
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e)=>handleKeyDown(e,()=>handleProgramTabClick("Coming Soon", "coming_soon"))}
                >
                  Coming Soon
                </div>
              </>
            )}

            <div className={`show-more-filter-container ${checkActiveClass()}`}>
              <SidebarModal
                gpa={gpa}
                setGpa={setGpa}
                amount={amount}
                setAmount={setAmount}
                gradeLevel={gradeLevel}
                setGradeLevel={setGradeLevel}
                programType={programType}
                setProgramType={setProgramType}
                filters={filters}
                addFilters={addFilters}
                removeFilters={removeFilters}
                setDataLoad={setDataLoad}
                showFilters={showFilters}
              />{" "}
            </div>

            {/* {
                       !isMobile && <div className="filter-selector__trailing" style={{ float: 'right' , marginLeft:"25px", height:"30px"}}>
                           {(globalSearch) ? renderGlobalSearch() : null}
                       </div>
                   } */}
          </div>

          {!isMobile && (
            <div className="account-search-program-container">
              <div className="program-sponsors-container">
                {renderAccountSelector()}
              </div>
              <div className="search-program-container">
                {renderGlobalSearch()}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`filter-selector ${className} ${
          !showFilters ? "hideFilterLeftSide" : ""
        }  `}
        key={key}
      >
        {pageType !== 'DISBURSEMENTS' &&
          <div className="filter-account-select">
            <div className="program-statistics">
              {showFilters && (
                <div className="filter-selector__heading">
                  <h2 className="filter-selector__title H1DesktopGreen color__green">
                    {USER_CONTEXT.APPLICANT === userContext && <span></span>}
                    {notShowHead && renderHeading()}
                  </h2>
                  {/* {renderStateSelector()} */}
                </div>
              )}
            </div>
            <div className="marketplace-account-selector">
              {/* {!isMobile && USER_CONTEXT.APPLICANT === userContext && renderAccountSelector()} */}
            </div>
          </div>
        }

        {renderMobileFilterBar(Object.keys(activeFilters).length > 0)}

        <div
          className={`filter-selector__items ${
            mobileFilterOpen ? "is-active" : ""
          }`}
        >
          <div className="filter-selector__top-bar">
            <button
              className="filter-selector__close-btn"
              onClick={toggleMobileFilterModal}
            >
              X
            </button>
            <span className="filter-selector__title BodyDefaultBold color__green">
              Filters
            </span>
            <button
              className="filter-selector__clear-btn CTA color__green"
              onClick={clearAll}
            >
              Clear
            </button>
          </div>
          {(cookies("context").context == "DONOR" || (isAchInfoFilters && cookies("context").admincontext == "ADMIN")) && renderFilters()}

          <button
            className="filter-selector__results-btn CTA background-color__black color__white"
            onClick={toggleMobileFilterModal}
          >
            {`Show ${count} ${count === 1 ? "Result" : "Results"}`}
          </button>
        </div>
      </div>

      {(USER_CONTEXT.APPLICANT === userContext || checkLogout) &&
        programFilterTabs()}

      {!showFilters &&  (
        <div className="donation-forms-custom-tabs">
          {filterButtonScholarships.includes('Donate') && <span className="donation-forms-custom-tabs-Disply BodyDefaultRegularBlack" style={{fontWeight: '600'}}>Display</span>}
          {filterButtonScholarships?.map((filter) => {
            return (
              <div
                onClick={(e) => handleDonationClick(e, filter)}
                className={`donation-forms-custom-tabs-button ${
                  filter === scholarshipButtonActive ? "active" : "Inactive"
                }`}
              >
                <span className="label">{filter}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

FilterSelector.propTypes = {
  activeFilters: PropTypes.object,
  pageType: PropTypes.string,
  label: PropTypes.shape({
        repeat: PropTypes.any
  }),
};

export default FilterSelector;